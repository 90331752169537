import { render, staticRenderFns } from "./ReceivingDataImportError.vue?vue&type=template&id=554fdc48&scoped=true"
import script from "./ReceivingDataImportError.vue?vue&type=script&lang=js"
export * from "./ReceivingDataImportError.vue?vue&type=script&lang=js"
import style0 from "./ReceivingDataImportError.vue?vue&type=style&index=0&id=554fdc48&prod&lang=css"
import style1 from "./ReceivingDataImportError.vue?vue&type=style&index=1&id=554fdc48&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "554fdc48",
  null
  
)

export default component.exports